import jsPDF from 'jspdf';
import { AuthContext } from 'context/AuthContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GetCertificate } from 'services/certification.service';

interface ICertificate {
  id: string;
  title: string;
  image: {
    small: { url: string };
    medium: { url: string };
    original: { url: string };
  };
}

const Certificate = () => {
  const [certificate, setCertificate] = useState<ICertificate>();
  const { user } = useContext(AuthContext);

  const getCertificate = useCallback(async () => {
    const response = await GetCertificate();
    if (response) {
      setCertificate(response[0]);
    }
  }, []);

  useEffect(() => {
    getCertificate();
  }, [getCertificate]);

  const handleDownloadCertificate = async () => {
    if (!certificate?.image.original.url) return;

    try {
      const image = new Image();

      image.crossOrigin = 'anonymous';
      image.src = certificate.image.original.url;

      await new Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
      });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) return;

      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);

      const currentDate = new Date().toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });

      const headerText = 'A CIPA, certifica que';
      const bodyText1 = `concluiu com sucesso a jornada de abandono predial em caso de incêndio,`;
      const bodyText2 = `no dia ${currentDate}, e está apto a ganhar o selo`;
      const footerText = 'Sabedoria da CIPA.';

      context.font = '32px Arial';
      context.fillStyle = 'black';
      context.fillText(headerText, 120, 580);

      context.font = 'bold 64px Arial';
      context.fillStyle = '#B21C23';
      context.fillText(user?.name || 'Participante', 120, 660);

      context.font = '32px Arial';
      context.fillStyle = 'black';
      context.fillText(bodyText1, 120, 710);
      context.fillText(bodyText2, 120, 750);

      context.font = 'bold 32px Arial';
      context.fillStyle = 'black';
      context.fillText(footerText, 120, 790);

      const imageData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'px', [canvas.width, canvas.height]);
      pdf.addImage(imageData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('certificado.pdf');
    } catch (error) {
      console.error('Erro ao processar a imagem:', error);
    }
  };

  return (
    <div className="flex flex-col space-y-5 justify-center items-center w-full text-white">
      <button onClick={handleDownloadCertificate} className="bg-blue-500 text-white px-4 py-2 rounded border-white border">
        Baixar Certificado
      </button>
    </div>
  );
};

export default Certificate;
